import { GTM } from "@/components/GTM";
import Layout from "@/components/Layout";
import HeroSection from "@/components/privacy/HeroSection";
import PageContent from "@/components/privacy/PageContent";
import React from "react";

const Privacy = () => {
  return (
    <Layout>
      <main>
        <HeroSection />
        <PageContent />
      </main>
    </Layout>
  );
};

export default Privacy;

export const Head = () => {
  return (
    <>
      <GTM />
      <title>Privacy Policy | Multiply</title>;
      <meta
        name="description"
        content="Multiply's platform gives you the power to provide automated advice, across a customer's entire life. Join the leading financial institutions improving engagement, retention, and conversions with the next generation of financial advice."
      ></meta>
      <meta
        name="og:description"
        content="Multiply's platform gives you the power to provide automated advice, across a customer's entire life. Join the leading financial institutions improving engagement, retention, and conversions with the next generation of financial advice."
      ></meta>
      <link rel="canonical" href="https://www.multiply.ai/" />
      <link rel="canonical" href="https://blog.multiply.ai/" />
    </>
  );
};
