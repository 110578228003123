import React from "react";
import * as style from "@/styles/privacy/herosection.module.css";

const HeroSection = () => {
  return (
    <section aria-label="hero section" className={style.wrapper}>
      <div className={style.contentWrapper}>
        <div className={style.content}>
          <h1 className={style.title}>Privacy Policy</h1>
          <p className={style.subtitle}>Last updated Oct 19, 2022</p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
