import React from "react";
import * as style from "@/styles/privacy/privacy.module.css";

const PageContent = () => {
  return (
    <section aria-label="privacy" className={style.section}>
      <div className={style.wrapper}>
        <div id="privacyContent" className={style.bannerWrapper}>
          <h2>PRIVACY POLICY</h2>
          <p>
            This privacy notice for Multiply AI Limited (doing business as
            Multiply) (‘<strong>Multiply</strong>', ‘<strong>we</strong>', ‘
            <strong>us</strong>', or ‘<strong>our</strong>') describes how and
            why we might collect, store, use and/or share (‘
            <strong>process</strong>') your information when you use our
            services (‘<strong>Services</strong>'), such as when you:
          </p>
          <ul className={style.privacyUl}>
            <li>
              Visit our website at{" "}
              <a href="https://www.multiply.ai" target="_blank">
                https://www.multiply.ai
              </a>
              , or any website of ours that links to this privacy notice
            </li>
            <li>
              Engage with us in other related ways, including any sales,
              marketing or events
            </li>
          </ul>
          <p>
            <strong>Questions or concerns? </strong>By reading this privacy
            policy, you will learn about your privacy rights and what you can do
            to protect them. If you do not agree with our policies and
            practices, please don't use our Services. If you still have
            questions or concerns, please email us at{" "}
            <a className={style.textlink} href="mailto:support@multiply.ai">
              support@multiply.ai
            </a>
            .
          </p>
          <h3>SUMMARY OF KEY POINTS</h3>
          <p>
            This summary provides key points from our privacy policy, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contents
            below to find the section you are looking for. You can also click{" "}
            <a href="#toc" className={style.textlink}>
              here
            </a>{" "}
            to go directly to our table of contents.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>What personal information do we process?</strong> When you
            visit, use, or navigate our website, we may process personal
            information depending on how you use Multiply and our services, the
            choices you make, and the products and features you use. To find out
            more, click{" "}
            <a href="#info1" className={style.textlink}>
              here
            </a>
            .
          </p>
          <p>
            <strong>Do we process any sensitive personal information?</strong>{" "}
            We do not process sensitive personal information.
          </p>
          <p>
            <strong>Do we receive any information from third parties?</strong>{" "}
            We do not receive any personal information from third parties.{" "}
          </p>
          <p>
            <strong>How do we process your information?</strong> We use your
            information to provide, improve, and administer our services, to get
            in touch with you, keep you safe, stop fraud, and comply with the
            law. If you consent, we may also use your information for other
            purposes. We process your information only when we have a valid,
            legal reason to do so.
          </p>
          <p>
            <strong>
              In what situations and with which types of parties do we share
              personal information?
            </strong>{" "}
            We may share information with some third parties. Read more{" "}
            <a href="#info4" className={style.textlink}>
              here
            </a>
            .
          </p>
          <p>
            <strong>How do we keep your information safe? </strong>
            Organisational and technical processes protect your personal
            information. However, no internet transmission or data-storage
            technology is 100% secure. We can't guarantee that hackers,
            cybercriminals, or other unauthorised third parties won't circumvent
            our security and collect, access, steal, or change your information
            without your permission. Read more{" "}
            <a href="#info7" className={style.textlink}>
              here
            </a>
            .
          </p>
          <p>
            <strong>What are your rights?</strong> Depending on where you live,
            you may have privacy rights regarding your personal information.
            Click here to read{" "}
            <a href="#info9" className={style.textlink}>
              more
            </a>
            .
          </p>
          <p>
            <strong>How do you exercise your rights? </strong>Email{" "}
            <a className={style.textlink} href="mailto:support@multiply.ai">
              support@multiply.ai
            </a>{" "}
            to exercise your rights. We'll respond to any request according to
            data protection laws.
          </p>
          <hr />
          <h3 id="toc">TABLE OF CONTENTS</h3>
          <p>
            <a href="#info1" className={style.toclink}>
              1. What information do we collect?
            </a>
          </p>
          <p>
            <a href="#info2" className={style.toclink}>
              2. How do we process your information?
            </a>
          </p>
          <p>
            <a href="#info3" className={style.toclink}>
              3. What legal bases do we rely on to process your information?
            </a>
          </p>
          <p>
            <a href="#info4" className={style.toclink}>
              4. When and with whom do we share your personal information?
            </a>
          </p>
          <p>
            <a href="#info5" className={style.toclink}>
              5. Do we use cookies and other tracking technologies?
            </a>
          </p>
          <p>
            <a href="#info6" className={style.toclink}>
              6. How long do we keep your information?
            </a>
          </p>
          <p>
            <a href="#info7" className={style.toclink}>
              7. How do we keep your information safe?
            </a>
          </p>
          <p>
            <a href="#info8" className={style.toclink}>
              8. Do we collect information from Minors?
            </a>
          </p>
          <p>
            <a href="#info9" className={style.toclink}>
              9. What are your privacy rights?
            </a>
          </p>
          <p>
            <a href="#info10" className={style.toclink}>
              10. Controls for Do-Not-Track features
            </a>
          </p>
          <p>
            <a href="#info11" className={style.toclink}>
              11. Do we make updates to this policy?
            </a>
          </p>
          <p>
            <a href="#info12" className={style.toclink}>
              12. How can you contact us about this policy?
            </a>
          </p>
          <p>
            <a href="#info13" className={style.toclink}>
              13. How can you review, update, or delete the data we have about
              you?
            </a>
          </p>

          <hr />
          <h4 id="info1">1. What information do we collect?</h4>
          <p className={style.textbold}>
            Personal information that you give to us.
          </p>
          <p>
            <em>Everything you tell us about yourself.</em>
          </p>
          <p>
            We collect personal information from you when you tell us you want
            to know more about us or our products or services.
          </p>
          <p>&nbsp;</p>
          <p className={style.textbold}>
            Personal information provided by you.
          </p>
          <p>
            The personal information that we collect depends on the context of
            your interaction with us. We may collect the following types of
            personal information:
          </p>
          <ul className={style.privacyUl}>
            <li>Names</li>
            <li>Phone numbers</li>
            <li>Email addresses</li>
            <li>Job titles</li>
            <li>Contact preferences</li>
          </ul>
          <p>
            <strong>We do not process sensitive information.</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            All of the information you give us about yourself must be true,
            complete, and correct, and you must let us know if any of this
            information changes.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>
              What categories of personal information do we collect?
            </strong>
          </p>
          <p>
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </p>
          <p>&nbsp;</p>
          <ul className={style.privacyUl}>
            <li>
              <strong>Identifiers:</strong> Contact details, such as real name,
              postal address, telephone or mobile contact number, online
              identifier, Internet Protocol address, email address.
            </li>
            <li>
              <strong>Personal information categories listed in the</strong>
              <strong> California</strong>
              <strong> Customer Records statute:</strong> Name, contact
              information, employment.
            </li>
            <li>
              <strong>Geolocation data:</strong> device location (anonymised).
            </li>
            <li>
              <strong>Internet or other similar network activity:</strong>{" "}
              Online behaviour, interest data, and interactions with our and
              other websites, applications, systems and advertisements.
            </li>
            <li>
              Protected classification characteristics under California or
              federal law: Gender (anonymised)
            </li>
            <li>
              <strong>Professional or employment-related information: </strong>
              Business contact details (in order to provide you our services),
              job title, work history and professional qualifications (if you
              apply for a job with us).
            </li>
            <li>
              <strong>Inferences drawn from other personal information:</strong>{" "}
              Inferences drawn from any of the collected personal information
              listed about to create a profile or summary about, for example, an
              individual's preferences and characteristics.
            </li>
          </ul>
          <p>
            We may also collect other kinds of personal information from you
            when you interact with us in person, online, by phone, or by mail in
            the following situations:
          </p>
          <ul className={style.privacyUl}>
            <li>Getting help through our channels for customer service</li>
            <li>Taking part in customer surveys</li>
            <li>
              To help us deliver our services and to respond to your enquiries
            </li>
          </ul>
          <p>
            <strong>How do we use and share your personal information?</strong>
          </p>
          <p>
            Multiply AI Limited collects and shares your personal information
            through:
          </p>
          <p>&nbsp;</p>
          <ul className={style.privacyUl}>
            <li>Targeting cookies/Marketing cookies</li>
            <li>Social media cookies</li>
            <li>Beacons/Pixels/Tags</li>
          </ul>
          <p>
            <strong>Will your information be shared with anyone else?</strong>
          </p>
          <p>
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Each service provider is a for-profit entity that processes the
            information on our behalf.
          </p>
          <p>&nbsp;</p>
          <p>
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            ‘selling' of your personal information.
          </p>
          <p>&nbsp;</p>
          <p>
            In the last twelve (12) months, Multiply AI Limited has not sold any
            personal information to a third party for a business or commercial
            reason. In the past twelve (12) months, Multiply AI Limited has
            given the following types of personal information to third parties
            for business or commercial purposes:
          </p>
          <p>&nbsp;</p>
          <ul className={style.privacyUl}>
            <li>
              <strong>Identifiers:</strong> Contact details, such as real name,
              postal address, telephone or mobile contact number, online
              identifier, Internet Protocol address, email address.
            </li>
            <li>
              <strong>
                Personal information categories listed in the California
                Customer Records statute:
              </strong>{" "}
              Name, contact information, employment.
            </li>
            <li>
              <strong>Geolocation data:</strong> device location (anonymised).
            </li>
            <li>
              <strong>Internet or other similar network activity:</strong>{" "}
              Online behaviour, interest data, and interactions with our and
              other websites, applications, systems and advertisements.
            </li>
            <li>
              Protected classification characteristics under California or
              federal law: Gender (anonymised)
            </li>
            <li>
              <strong>Professional or employment-related information: </strong>
              Business contact details (in order to provide you our services),
              job title, work history and professional qualifications (if you
              apply for a job with us).
            </li>
            <li>
              <strong>Inferences drawn from other personal information:</strong>{" "}
              Inferences drawn from any of the collected personal information
              listed about to create a profile or summary about, for example, an
              individual's preferences and characteristics.
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            The categories of third parties to whom we disclosed personal
            information for a business or commercial purpose can be found under
            the heading “When and with whom do we share your personal
            information?”.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Your rights with respect to your personal data</strong>
          </p>
          <p>You have a right to:</p>
          <p>&nbsp;</p>
          <ul className={style.privacyUl}>
            <li>
              <span className={style.textu}>Access</span> the personal data we
              hold about you. You can ask us for a copy of the information we
              hold about you. We will not charge you if we provide you with a
              copy, but we may charge a reasonable administrative fee if you
              request further copies.
            </li>
            <li>
              Ask to to <span className={style.textu}>correct</span> any
              inaccurate data
            </li>
            <li>
              <span className={style.textu}>Object</span> to us holding certain
              data about you
            </li>
            <li>
              <span className={style.textu}>Withdraw</span> any consent you have
              given to us
            </li>
            <li>
              Ask us to <span className={style.textu}>erase</span> your data,
              though for legal reasons we might not always be able to do it
            </li>
            <li>
              <span className={style.textu}>Restrict</span> us from using your
              data for certain purposes (e.g. direct marketing, research, and in
              certain circumstances 'legitimate interests')
            </li>
            <li>
              Ask for a copy of your personal data in a portable
              (machine-readable) format so you can{" "}
              <span className={style.textu}>move</span> it elsewhere
            </li>
            <li>
              Submit a <span className={style.textu}>complaint</span> about how
              your data has been used. For more details, please see the ICO
              website at the following link:{" "}
              <a
                href="https://ico.org.uk/make-a-complaint"
                target="_blank"
                rel="nofollow"
              >
                https://ico.org.uk/make-a-complaint
              </a>
            </li>
          </ul>
          <p>
            To exercise these rights, you can email us at{" "}
            <a className={style.textlink} href="mailto:support@multiply.ai">
              support@multiply.ai
            </a>
            , visit{" "}
            <a
              className={style.textlink}
              href="https://www.multiply.ai/contact/"
              rel="nofollow"
            >
              https://www.multiply.ai/contact/
            </a>
            , or look at the bottom of this page for our contact information. If
            you have a problem with how we handle your data, please let us know.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Information automatically collected.</strong>
          </p>
          <p>
            <em>
              Automatically collected information which includes your IP address
              and the type of device you are using.
            </em>
          </p>
          <p>&nbsp;</p>
          <p>
            When you visit, use, or navigate the website, we automatically
            collect some information about you. This information does not reveal
            your name or contact information, but it may include information
            about your device and how you use it, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our website, and other
            technical information. This information is mostly needed to keep our
            website safe and running, as well as for our own internal analytics
            and reporting.
          </p>
          <p>&nbsp;</p>
          <p>
            Like many other businesses, we use cookies and other similar
            technologies to collect information. The information we collect
            includes:
          </p>
          <ul className={style.privacyUl}>
            <li>
              <span className={style.textu}>Log and Usage Data</span>. Log and
              usage data is information about service, diagnostics, usage, and
              performance that is automatically collected by our servers when
              you visit or use our website. This information is stored in log
              files. Depending on how you interact with us, this log data may
              include your IP address, device information, browser type, and
              settings, as well as information about what you do on the website
              (such as the date and time you used it, the pages and files you
              looked at, the searches you ran, and other actions you took, like
              which features you used). It may also include information about
              device events, such as system activity, error reports, and
              hardware settings.
            </li>
            <li>
              <span className={style.textu}>Device Data</span>. We get
              information about the computer, phone, tablet, or other device you
              use to access the website. Depending on the device used, this
              device data could include things like your IP address (or proxy
              server), device and application identification numbers, location,
              browser type, hardware model, Internet service provider and/or
              mobile carrier, operating system, and system configuration
              information.
            </li>
            <li>
              <span className={style.textu}>Location Data</span>. We collect
              location data, like information about where your device is, which
              can be precise or imprecise. The type and settings of the device
              you use to access the website affect how much information we
              collect. For example, we might use GPS and other technologies to
              find out where your current location is (based on your IP
              address). If you don't want us to collect this information, you
              can either refuse to give us access to it or turn off your
              device's Location setting. But if you opt out, you might not be
              able to use some parts of the website.
            </li>
          </ul>

          <h4 id="info2">2. How do we process your information?</h4>
          <p>
            We process your personal information for a variety of reasons,
            depending on how you interact with our website, including:
          </p>
          <p>&nbsp;</p>
          <ul className={style.privacyUl}>
            <li>
              <strong>
                To deliver and facilitate delivery of services to the user.
              </strong>{" "}
              We may use your information to give you the service you've asked
              for (e.g. booking a demo of our product).
            </li>
            <li>
              <strong>
                To respond to user inquiries/offer support to users.
              </strong>{" "}
              We may use the information you give us to answer your questions
              and fix any problems you might have with the site (e.g. you fill
              out a request on our contact form).
            </li>
            <li>
              <strong>To send administrative information to you.</strong> We may
              use the information you give us to send you information about our
              products and services, changes to our terms and policies, and
              similar information (e.g. you signed up to receive more updates
              from us).
            </li>
            <li>
              <strong>To request feedback. </strong>We may use the information
              you give us when we need to ask for feedback (e.g. via an on page
              survey)
            </li>
            <li>
              <strong>
                To send you marketing and promotional communications.
              </strong>{" "}
              We may use the personal information you send us for marketing if
              that's in line with your contact preferences. You can stop getting
              our marketing emails whenever you want (e.g. you subscribe to a
              marketing newsletter).
            </li>
            <li>
              <strong>To deliver targeted advertising to you. </strong>We may
              use the information you give us to create and show you content and
              ads that are based on your interests, location, and other factors
              (e.g. you visit a website and then notice a Multiply ad on another
              website).
            </li>
            <li>
              <strong>To protect our website and services. </strong>We may use
              your information as part of our efforts to make sure our website
              is safe and secure, such as monitoring and stopping fraud (e.g. we
              track any unusual activity on our website).
            </li>
            <li>
              <strong>To identify usage trends.</strong> We may use information
              about how you use our website to learn more about how it is used
              so that we can make it better (e.g. we find out more about which
              web pages are more popular than others).
            </li>
            <li>
              <strong>
                To determine the effectiveness of our marketing and promotional
                campaigns.
              </strong>{" "}
              We may use the information you give us to learn more about how to
              give you more relevant marketing and promotional campaigns (e.g.
              we can tailor our ads and content to better suit people of a
              similar demographic to you).
            </li>
            <li>
              <strong>
                To save or protect an individual's vital interest.
              </strong>{" "}
              We may use your information when it's necessary to save or protect
              the vital interests of a person, such as to prevent harm (e.g. if
              we are required by law to provide certain information).
            </li>
          </ul>

          <h4 id="info3">
            3. What legal bases do we rely on to process your information?
          </h4>
          <p className={style.textu}>
            If you are located in the EU or UK, this section applies to you.
          </p>
          <p>
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As a result, we may process your personal
            information based on the following legal bases:
          </p>
          <ul className={style.privacyUl}>
            <li>
              <strong>Consent.</strong> We can use your information if you have
              given us permission (also called "consent") to do so for a certain
              reason. You are free to change your mind at any time. See section
              9 for more information.
            </li>
            <li>
              <strong>Performance of a Contract.</strong> We may use your
              personal information when we think it's necessary to meet our
              obligations under our contract with you, such as providing our
              services, or when you ask us to do so before we enter into a
              contract with you.
            </li>
            <li>
              <strong>Legitimate Interests.</strong> We can use your information
              when we think it's reasonable to do so in order to meet our
              legitimate business interests, as long as those interests don't
              come before your interests, basic rights, and freedoms. For
              example, we may use some of the listed reasons to process your
              personal information in order to:
              <ul className={style.privacyUlLiUl}>
                <li>Tell people about our products and services.</li>
                <li>
                  Make and show our users personalised and relevant advertising
                  content
                </li>
                <li>
                  Look at how our services are used to see how we can improve
                  them to keep people interested and coming back.
                </li>
                <li>Help us with our marketing efforts</li>
                <li>Diagnose problems and/or prevent fraudulent activities</li>
                <li>
                  Learn how people use our products and services so that we can
                  make them better
                </li>
              </ul>
            </li>
            <li>
              <strong>Legal Obligations.</strong> We may process your
              information when we think it's necessary to meet our legal
              obligations, such as when we need to work with a law enforcement
              or regulatory agency, defend or exercise our legal rights, or use
              your information as evidence in litigation in which we are
              involved.
            </li>
            <li>
              <strong>Vital Interests.</strong> We may use your information if
              we think it is necessary to protect your vital interests or the
              vital interests of a third party. For example, if there is a
              threat to the safety of you or someone else.
            </li>
          </ul>
          <p className={style.textu}>
            If you are located in Canada, this section applies to you.
          </p>
          <p>
            We can use your information if you have given us clear permission
            (also called "express consent") to do so or if we can infer that you
            want us to (i.e. implied consent). You can withdraw your contact at
            any time. See section 9 for more information.
          </p>
          <p>&nbsp;</p>
          <p>
            In some exceptional cases, we may be allowed by law to use your
            information without your permission. For example:
          </p>
          <ul className={style.privacyUl}>
            <li>
              If it is clear that the collection is in the best interest of a
              person and consent cannot be gotten in a timely manner,
            </li>
            <li>For investigations and finding and stopping fraud</li>
            <li>
              If certain conditions are met, it can be used for business
              transactions.
            </li>
            <li>
              If it's in a witness statement and the information needs to be
              collected to evaluate, process, or settle an insurance claim.
            </li>
            <li>
              For identifying injured, ill, or deceased persons and getting in
              touch with their next of kin.
            </li>
            <li>
              If we have good reason to think someone has been, is being, or
              could be a victim of financial abuse
            </li>
            <li>
              If it seems likely that collecting and using the information with
              the person's permission would compromise the information's
              availability or accuracy, and if collecting the information is
              necessary for investigating a breach of an agreement or a
              violation of a law in Canada or a province.
            </li>
            <li>
              If it's needed to comply with a subpoena, warrant, court order, or
              the rules of the court about the production of records, the
              information must be given out.
            </li>
            <li>
              If the information was made by a person in the course of their
              job, business, or profession and the collection is in line with
              why the information was made in the first place.
            </li>
            <li>
              If the collection is only for journalistic, artistic, or literary
              purposes
            </li>
            <li>
              If the information is available to the public and is specified by
              the regulations
            </li>
          </ul>

          <h4 id="info4">
            4. When and with whom do we share your personal information?
          </h4>
          <p>
            <strong>
              Vendors, Consultants and Other Third-Party Service Providers
            </strong>
            <strong>.</strong> We may share your information with third-party
            vendors, service providers, contractors, or agents ("third parties")
            who work for us or on our behalf and need access to such information
            to do their jobs. We have agreements with third parties that are
            meant to keep your personal information safe. This means that they
            won't be able to do anything with your private information unless we
            tell them to. They also won't give your personal information to any
            other company besides us. They promise to keep our information safe
            and only keep it for as long as we tell them to. Here are the types
            of third parties with whom we may share personal information:
          </p>
          <ul className={style.privacyUl}>
            <li>Ad Networks</li>
            <li>Communication & Collaboration Tools</li>
            <li>Data Analytics Services</li>
            <li>Data Storage Service Providers</li>
            <li>Retargeting Platforms</li>
            <li>Sales & Marketing Tools</li>
            <li>Website Hosting Service Providers</li>
            <li>Social Networks</li>
          </ul>
          <p>
            Your personal information may also need to be shared in the
            following situations:
          </p>
          <ul className={style.privacyUl}>
            <li>
              <strong>Business Transfers. </strong>We may share or transfer your
              information in connection with, or during negotiations or, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
            <li>
              <strong>Business Partners.</strong> We might tell our business
              partners about you so they can offer you certain products,
              services, or deals.
            </li>
          </ul>

          <h4 id="info5">
            5. Do we use cookies and other tracking technologies?
          </h4>
          <p>
            We may use cookies and similar tracking technologies to access or
            store information. In our Cookie Policy, we explain how we use these
            technologies and how you can choose not to accept certain cookies.
          </p>

          <h4 id="info6">6. How long do we keep your information?</h4>
          <p>
            We'll only keep your information for as long as it's needed for the
            purposes outlined in this privacy policy, unless the law requires or
            lets us keep it for longer (such as tax, accounting, or other legal
            requirements).
          </p>
          <p>&nbsp;</p>
          <p>
            When we no longer have a legitimate business need to process your
            personal information, we will either delete it or make it anonymous.
            If this is not possible (for example, if your personal information
            is stored in backup archives), we will store it safely and keep it
            from being used for anything else until it can be deleted.
          </p>

          <h4 id="info7">7. How do we keep your information safe?</h4>
          <p>
            We have put in place reasonable and appropriate technical and
            organisational security measures to protect any personal information
            we handle. Despite our precautions and efforts to keep your
            information safe, we can't promise or guarantee that hackers,
            cybercriminals, or other unauthorised third parties won't be able to
            get around our security and collect, access, steal, or change your
            information. We will do our best to keep your personal information
            safe, but it is at your own risk to send personal information to and
            from our website. You should only access the website when you are in
            a secure environment.
          </p>

          <h4 id="info8">8. Do we collect information from Minors?</h4>
          <p>
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the website, you are saying that you are at
            least 18 years old or that you are the parent or guardian of a minor
            and give permission for that minor to use the website. If we find
            out that personal information from users younger than 18 years old
            has been collected, we will do what we can to dispose of that
            information as soon as possible. If you discover that we may have
            information about children under the age of 18, please let us know
            at{" "}
            <a className={style.textlink} href="mailto:support@multiply.ai">
              support@multiply.ai
            </a>
            .
          </p>

          <h4 id="info9">9. What are your privacy rights?</h4>
          <p>
            In some regions, like the EEA, the UK, and Canada, there are laws
            that protect your personal information and give you certain rights.
            These may include the (i) right to request access and obtain a copy
            of your personal information, (ii) to request rectification or
            erasure, (iii) to restrict the processing of your personal
            information and (iv) if applicable, to data portability. You may
            also have the right to say you don't want your personal information
            to be used in certain ways. You can ask for this by getting in touch
            with us using the information in the section "How can you get in
            touch with us about this policy?" ' below.
          </p>
          <p>&nbsp;</p>
          <p>
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </p>
          <p>&nbsp;</p>
          <p>
            If you live in the EEA or UK and think we are using your personal
            information in an unlawful way, you can also file a complaint with
            your local data protection authority. You can find out how to get in
            touch with them here:
            <br />
            EEA:{" "}
            <a
              className={style.textlink}
              href="https://ec.europa.eu/info/departments/data-protection-officer_en"
              target="_blank"
              rel="nofollow"
            >
              https://ec.europa.eu/info/departments/data-protection-officer_en
            </a>
            .<br />
            UK:{" "}
            <a
              className={style.textlink}
              href="https://ico.org.uk"
              target="_blank"
              rel="nofollow"
            >
              https://ico.org.uk
            </a>
            .
          </p>
          <p>&nbsp;</p>
          <p>
            If you are located in Switzerland, the contact details for the data
            protection authorities are available here:{" "}
            <a
              className={style.textlink}
              href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html"
              target="_blank"
              rel="nofollow"
            >
              https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html
            </a>
            .
          </p>
          <p>&nbsp;</p>
          <p>
            <span className={style.textboldu}>Withdrawing your consent:</span>{" "}
            If we are relying on your consent to process your personal
            information, which may be expressed and/or implied depending on the
            law, you have the right to withdraw your consent at any time by
            contacting us using the information in the "How can you contact us
            about this policy?" section. " below.
          </p>
          <p>&nbsp;</p>
          <p>
            Please note, though, that this won't affect the lawfulness of the
            processing that happened before you withdrew your consent, nor will
            it change the processing of your personal information that was done
            based on lawful processing grounds other than consent.
          </p>
          <p>&nbsp;</p>
          <p>
            <span className={style.textboldu}>
              Opting out of marketing and promotional communications:
            </span>{" "}
            You can stop getting our marketing and promotional emails at any
            time by clicking the "unsubscribe" link in them or by getting in
            touch with us using the information in the "How can you contact us
            about this policy?" section. " below. You will then be removed from
            the marketing lists. We may still contact you, for example, to send
            you service-related messages that are necessary for the
            administration and use of your account, to respond to service
            requests, or for other non-marketing purposes.
          </p>
          <p>&nbsp;</p>
          <p>
            <span className={style.textboldu}>
              Cookies and similar technologies:
            </span>{" "}
            Most web browsers are set to automatically accept cookies. If you
            prefer, you can usually choose to set your browser to remove cookies
            and reject cookies. If you choose to remove cookies or reject
            cookies, this could affect certain features of our website. To opt
            out of interest-based advertising by advertisers on our services
            visit{" "}
            <a
              className={style.textlink}
              href="https://optout.aboutads.info/"
              target="_blank"
              rel="nofollow"
            >
              https://optout.aboutads.info/
            </a>
            .
          </p>
          <p>&nbsp;</p>
          <p>
            If you have questions or comments about your privacy rights, you may
            email us at{" "}
            <a className={style.textlink} href="mailto:support@multiply.ai">
              support@multiply.ai
            </a>
            .
          </p>

          <h4 id="info10">10. Controls for Do-Not-Track features</h4>
          <p>
            Most web browsers and some mobile operating systems and mobile apps
            have a "Do-Not-Track" (DNT) setting or feature that you can turn on
            to show that you don't want your online browsing activities to be
            tracked and recorded. At this point, there is no finalised
            technology standard for recognising and using DNT signals. As a
            result, we do not currently respond to DNT browser signals or any
            other way that your choice not to be tracked online is automatically
            sent to us. If an online tracking standard is made that we must
            follow in the future, we will let you know about it in a revised
            version of this privacy policy.
          </p>

          <h4 id="info12">11. Do we make updates to this policy?</h4>
          <p>
            Yes, we will change this policy as needed to make sure it stays
            compliant with relevant laws.
          </p>
          <p>
            The updated version will have a new "revised" date, and it will take
            effect as soon as it is accessible on the website. If we make
            material changes to this privacy policy, we may let you know by
            putting a notice in a prominent place. We encourage you to read this
            privacy policy frequently so you know how we're keeping your
            information safe.
          </p>
          <h4>12. How can you contact us about this policy?</h4>
          <p>
            If you have questions or comments about this policy, you can send an
            email to our Data Protection Officer (DPO), at{" "}
            <a className={style.textlink} href="mailto:dpo@multiply.ai">
              dpo@multiply.ai
            </a>
            , or send a letter to:
          </p>
          <p>&nbsp;</p>
          <p>
            Multiply Limited AI
            <br />
            ATTN: DPO
            <br />
            24 Arlington Square
            <br />
            London
            <br />
            N1 7DP
            <br />
            United Kingdom
          </p>

          <h4 id="info13">
            13. How can you review, update, or delete the data we have about
            you?
          </h4>
          <p>
            You have the right to find out what personal information we hold
            about you, change it, or delete it. If you want to see, change, or
            delete the personal information we have about you, please send us an
            email at{" "}
            <a className={style.textlink} href="mailto:support@multiply.ai">
              support@multiply.ai
            </a>
            .
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
    </section>
  );
};

export default PageContent;
